import * as React from "react";
import { WizardLXPWAFlexModuleResult, WizardLXPWAProps } from "./typings";
import { inject, observer } from "mobx-react";
import { WizardLXPWAView } from "./WizardLXPWAView";
import { getOffsetDatesByDatesOption } from "src/components/utility/OffsetDate/OffsetDateUtil";

@inject("wizardState", "flexModuleModelStore", "page")
@observer
export class WizardLXPWA extends React.Component<WizardLXPWAProps> {
  constructor(props: WizardLXPWAProps) {
    super(props);

    const { lxWizardState } = this.props.wizardState;
    const { location } = this.props.context.searchContext;
    const { flexModuleModelStore, templateComponent } = props;

    if (templateComponent && flexModuleModelStore) {
      const { metadata } = templateComponent;
      const { id } = metadata;
      const model = flexModuleModelStore.getModel(id) as WizardLXPWAFlexModuleResult | null;

      if (model) {
        const { placeholderDestination: setDefaultDestination = true } = model;
        const { visibleLobSubtitle } = model;
        lxWizardState.overrideConfig(() => {
          lxWizardState.config.elementId = id;
          lxWizardState.config.heading.fixedTitle = model.fixedLobTitle;
          lxWizardState.config.heading.lobSubtitleBreakPoint = model.lobSubtitleBreakPoint;
          lxWizardState.config.heading.hideLobTitle = model.hideLobTitle;
          lxWizardState.config.heading.visibleLobSubtitle =
            visibleLobSubtitle ?? lxWizardState.config.heading.visibleLobSubtitle;
        });

        if (location != null && setDefaultDestination) {
          lxWizardState.setDefaultDestination(
            location.extendedName,
            location.id,
            location.parent?.code || "",
            location.code,
            "",
            ""
          );
        }

        const { defaultOffsetDates } = lxWizardState.config.date;
        const { startDateOffset, endDateOffset } = getOffsetDatesByDatesOption(model);

        if (defaultOffsetDates && startDateOffset !== undefined && endDateOffset !== undefined) {
          defaultOffsetDates.start = startDateOffset;
          defaultOffsetDates.end = endDateOffset;
        }

        lxWizardState.updateDateFromConfig();
      }
    }
  }

  public render() {
    return <WizardLXPWAView {...this.props} />;
  }
}

export default WizardLXPWA;
