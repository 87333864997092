import * as React from "react";
import { WizardLXPWAFlexModuleResult, WizardLXPWAProps } from "./typings";
import { Location } from "src/components/shared/Location/Location";
import { observer, MobXProviderContext } from "mobx-react";
import { LobTitle } from "src/components/shared/LobTitle/LobTitle";
import { SubmitRow } from "src/components/shared/StorefrontWizard/SubmitRow/SubmitRow";
import { Dates } from "src/components/shared/Dates/Dates";
import { ClientWizardStateStore } from "stores/wizard/ClientWizardStateStore";
import { LobSubtitleWithLink } from "src/components/shared/LobSubtitle/LobSubtitleWithLink";
import {
  UitkLayoutGrid,
  UitkLayoutConditionalGridSpan,
  UitkLayoutConditionalGridTrack,
} from "@egds/react-core/layout-grid";
import { LobErrorSummary } from "src/components/shared/LobErrorSummary/LobErrorSummary";
import { LobSubtitle } from "src/components/shared/LobSubtitle/LobSubtitle";
import { getShoppingGlobalMicroMessage } from "components/shared/StorefrontWizard/SubmitRow/UISShoppingGlobal";
import { SubmitButton } from "components/shared/SubmitButton/SubmitButton";
import { UitkSpacing } from "@egds/react-core/spacing";
import { Columns } from "components/shared/StorefrontWizard/typings";
import { Action } from "src/components/utility/analytics/FlexAnalyticsUtils";
import { getFmId } from "src/stores/ExperienceTemplateStore";

export const WizardLXPWAView: React.FunctionComponent<WizardLXPWAProps> = observer(
  ({ templateComponent, flexModuleModelStore, page }) => {
    const wizardState: ClientWizardStateStore = React.useContext(MobXProviderContext).wizardState;
    const {
      globalWizardState,
      lxWizardState,
      lxWizardState: { config: lxConfig },
      lxWizardState: {
        config: { heading },
      },
    } = wizardState;

    if (!templateComponent || !flexModuleModelStore) {
      return null;
    }

    const { metadata } = templateComponent;
    const { id } = metadata;
    const model = flexModuleModelStore.getModel(id) as WizardLXPWAFlexModuleResult | null;

    if (!model) {
      return null;
    }

    const fmTitleId = templateComponent.config.fmTitleId;
    const fmId = getFmId(templateComponent);

    const { halfWizard } = wizardState.globalWizardState.config;
    const { searchButtonAlongsideDates = false } = model;

    const locationColumns: Columns = {
      fullWidth: {
        small: 1,
        medium: searchButtonAlongsideDates ? 5 : 7,
      },
      half: {
        small: 1,
        medium: 6,
        large: 6,
      },
    };

    const datesColumns: Columns = {
      fullWidth: {
        small: 1,
        medium: 5,
        large: 5,
      },
      half: {
        small: 1,
        medium: 6,
        large: 6,
      },
    };

    const submitColumns: Columns = {
      fullWidth: {
        small: 1,
        medium: 2,
      },
      half: {
        small: 1,
        medium: 2,
      },
    };

    const mainGridColumns: UitkLayoutConditionalGridTrack = { small: 1, medium: 12 };
    const locationColSpan: UitkLayoutConditionalGridSpan = locationColumns[halfWizard ? "half" : "fullWidth"];
    const datesColSpan: UitkLayoutConditionalGridSpan = datesColumns[halfWizard ? "half" : "fullWidth"];
    const submitColSpan: UitkLayoutConditionalGridSpan = submitColumns[halfWizard ? "half" : "fullWidth"];

    const { visibleLobSubtitle, locSubtitleTextToken, locSubtitleLinkToken, locSubtitleLinkUrl } = heading;
    const uisShoppingGlobal = getShoppingGlobalMicroMessage(lxWizardState);

    const setInputsRefs = (ref: React.RefObject<HTMLInputElement>, secondRef?: React.RefObject<HTMLInputElement>) => {
      lxWizardState.wizardInputsArray.push(ref);
      if (secondRef) {
        lxWizardState.wizardInputsArray.push(secondRef);
      }
    };

    const getLocError = (isLink: boolean) => {
      if (!isLink) {
        return lxWizardState.numberOfErrors
          ? lxConfig.form.submitButton.locWarningsWithNumberToken
          : lxConfig.form.submitButton.locWarningsToken;
      }

      return lxWizardState.moreThanOneError()
        ? lxConfig.form.submitButton.locFirstWarningLinkToken
        : lxConfig.form.submitButton.locWarningLinkToken;
    };

    const pageName = page ? page.pageName : "unknown";
    const moduleName = halfWizard ? "HalfBiasedWizard" : "FullBiasedWizard";
    const linkName = "ActivitySearch";
    const sectionName = "SQM";
    const rfrrid = `${pageName}.${moduleName}.${linkName}.${sectionName}`;
    const extraTracking: any = {
      schemaName: "referrer",
      messageContent: {
        referrerId: rfrrid,
        eventType: Action.CLICK,
      },
    };

    uisShoppingGlobal.push(extraTracking);

    return (
      <form
        noValidate
        action={lxConfig.form.action}
        autoComplete="off"
        className="WizardLXPWA"
        id={lxConfig.elementId}
        data-fm={fmId}
        data-fm-title-id={fmTitleId}
        data-testid="wizard"
        onSubmit={lxWizardState.submit}
      >
        {!halfWizard && <LobTitle lobState={lxWizardState} />}
        {!halfWizard && <LobSubtitle lobState={lxWizardState} />}
        {!halfWizard && visibleLobSubtitle && (
          <UitkSpacing padding={{ block: "four" }} margin={{ blockstart: "one", blockend: "half" }}>
            <LobSubtitleWithLink
              locKey={locSubtitleTextToken!}
              locLinkKey={locSubtitleLinkToken!}
              linkUrl={locSubtitleLinkUrl!}
            />
          </UitkSpacing>
        )}
        <UitkSpacing padding={{ blockstart: "three", blockend: "three" }}>
          <UitkLayoutGrid columns={mainGridColumns} space="three">
            <>
              {lxWizardState.numberOfErrors > 0 && (
                <LobErrorSummary
                  locHeadingToken={getLocError(false)!}
                  locHeadingArgs={lxWizardState.moreThanOneError() && [lxWizardState.numberOfErrors]}
                  locLinkToken={getLocError(true)}
                  linkClickFocusId="id-roundtrip-error"
                  inputErrorRef={lxWizardState.errorInputRef}
                  colSpan={mainGridColumns as UitkLayoutConditionalGridSpan}
                  setInputsRefs={setInputsRefs}
                />
              )}
              <Location
                setInputsRefs={setInputsRefs}
                originField={false}
                destinationField
                lobState={lxWizardState}
                colSpan={locationColSpan}
                globalConfig={globalWizardState.config}
              />
              <Dates lobState={lxWizardState} colSpan={datesColSpan} singleDateSelect setInputsRefs={setInputsRefs} />
              {searchButtonAlongsideDates && (
                <>
                  <SubmitButton
                    lobState={lxWizardState}
                    colSpan={submitColSpan}
                    rfrr="SearchClick_LX"
                    uisPrimeMicroMessages={uisShoppingGlobal}
                  />
                </>
              )}
            </>
          </UitkLayoutGrid>
        </UitkSpacing>
        {!searchButtonAlongsideDates && (
          <SubmitRow
            lobState={lxWizardState}
            rfrr="SearchClick_LX"
            extraUISTracking={extraTracking}
            globalConfig={globalWizardState.config}
          />
        )}
      </form>
    );
  }
);

export default WizardLXPWAView;
